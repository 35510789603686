import React, { useEffect, useMemo, useState } from "react";
import {
  useParams,
  useLocation,
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import PublicShopApp from "../../containers/PublicShopApp";
import { reducers } from "../../store/configureShopStore";
import withReducers from "../../store/withReducers";
import NoMatch from "../NoMatch";
import { getQueryParams } from "../../utils";
import ErrorBoundary from "../ErrorBoundary";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductItems, setCart } from "../../actions/shop";
import { loadCartStateFromLocalStorage } from "../../utils/cartLocalStorage";

const AppRoute = React.memo(({ panel }) => {
  const params = useParams();
  const { shop_id } = useParams();
  const location = useLocation();
  const queryParams = getQueryParams({ location });
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.entities.orders);
  const itemColors = useSelector((state) => state.entities.colors);
  const itemSkus = useSelector((state) => state.entities.skus);
  const itemSizes = useSelector((state) => state.entities.sizes);
  const items = useSelector((state) => state.entities.items);
  const order_id = Object.keys(orders || {})?.[0] ?? "";

  const optionItems = useMemo(
    () =>
      Object.keys(items).filter((key) => items[key].parent_type === "OPTION"),
    [items]
  );

  useEffect(() => {
    const cart = loadCartStateFromLocalStorage(
      shop_id,
      itemSkus,
      itemColors,
      itemSizes,
      optionItems
    );

    dispatch(setCart(cart));
    Object.keys(cart).length > 0 &&
      Object.keys(cart).map((item_id) => {
        return order_id && item_id
          ? dispatch(fetchProductItems(item_id, order_id))
          : null;
      });
  }, [dispatch, order_id, shop_id]);

  if (panel) {
    return (
      <PublicShopApp
        panel={panel}
        params={{ ...queryParams, ...params }}
        location={location}
      />
    );
  }

  return <NoMatch location={location} />;
});

const PublicShopRouter = ({ location }) => (
  <BrowserRouter basename={location}>
    <Routes>
      <Route
        path="/shop/:shop_id"
        element={<AppRoute panel="home" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_id/shop"
        element={<AppRoute panel="shop" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_id/shop/:item_id"
        element={<AppRoute panel="shop" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_id/cart"
        element={<AppRoute panel="cart" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_id/checkout"
        element={<AppRoute panel="checkout" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_id/receipt"
        element={<AppRoute panel="receipt" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={"*"}
        element={<AppRoute noMatch />}
        ErrorBoundary={ErrorBoundary}
      />
    </Routes>
  </BrowserRouter>
);

export default withReducers(PublicShopRouter, reducers, true);
